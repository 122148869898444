<template>
  <el-config-provider :locale="locale">
    <router-view />
    <div style="position:fixed;text-align:center;bottom:0;margin:0 auto;width:100%;color: #5c6b77">
      <a target="_blank" style="color: #5c6b77" href="http://www.beian.miit.gov.cn">津ICP备2024010927号-1</a>
    </div>
  </el-config-provider>

</template>


<script>
import { ElConfigProvider } from 'element-plus'

import zhCn from 'element-plus/lib/locale/lang/zh-cn'

export default {
  name: "App",
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
    }
  },
}
</script>
